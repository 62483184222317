import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout/layout';
import { H1, H2, Subheader, Link } from '../components';

export default function Experience() {
  return (
    <Layout title="Glassware">
      <div className="w-full px-10 pt-32 md:pt-40 lg:pt-60">
        <div className="w-full flex flex-col justify-center items-center text-center">
          <H1>Experience Lokabrews</H1>
          <div className="w-full max-w-screen-2xl grid grid-cols-1 lg:grid-cols-10 gap-5">
            <Link
              visible={false}
              to="/food-pairing"
              className="lg:col-span-8 xl:col-span-7 border-4 border-primary flex flex-col text-left bg-secondary text-white"
            >
              <StaticImage src="../assets/images/experience/food-pairing.jpg" alt="Server with two plates of food" />
              <H2 className="pt-5 px-5 override:text-white override:font-bold">Gastronomic Pairings</H2>
              <Subheader className="px-5 override:text-white override:font-normal" asH3>
                Elevate food and beer drinking experiences
              </Subheader>
            </Link>
            <div className="lg:col-span-2 xl:col-span-3 w-full flex flex-col gap-5">
              <Link
                visible={false}
                to="/glassware"
                className="border-4 border-primary flex flex-col text-left bg-secondary text-white"
              >
                <StaticImage src="../assets/images/experience/glassware.jpg" alt="Three Lokabrews beer glasses" />
                <H2 className="pt-5 px-5 override:text-white override:font-bold">Loka Belgian Beer Glassware</H2>
                <Subheader className="px-5 override:text-white override:font-normal" asH3>
                  Experience Tradition
                </Subheader>
              </Link>
              <Link
                visible={false}
                to="/stories"
                className="border-4 border-primary flex flex-col text-left bg-secondary text-white"
              >
                <StaticImage
                  src="../assets/images/experience/stories.jpg"
                  alt="Three Lokabrews beer bottles in front of a building in Belgium"
                />
                <H2 className="pt-5 px-5 override:text-white override:font-bold">Stories</H2>
                <Subheader className="px-5 override:text-white override:font-normal" asH3>
                  For a connected communitiy
                </Subheader>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
